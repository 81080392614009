import React, {useState} from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ProductTable from "../components/product-table"

export default function Shop({data}) {

    const [filter, setFilter] = useState("")
    const pageTitle = "Product List"
    const pageDescription = "Shop Page. List of Products Page. Online Order Page. Independent Forensics is a unique DNA research and analysis laboratory with a powerful record of breaking through barriers in commercial science. Our experienced team has over 60 combined years experience in the DNA and related scientific fields."

    let curProducts;
    let filterProducts = data.products.edges;

    if(filter === "") {
        curProducts = filterProducts;
    } else if(filter ==="rsid" ){
        curProducts = filterProducts.filter(({node}) => node.group === filter)
    } else if(filter ==="hyliter" ){
        curProducts = filterProducts.filter(({node}) => node.group === filter)
    } else if(filter ==="onetouch" ){
        curProducts = filterProducts.filter(({node}) => node.group === filter)
    } else if(filter ==="amplicon" ){
        curProducts = filterProducts.filter(({node}) => node.group === filter)
    } else if(filter ==="iplex" ){
        curProducts = filterProducts.filter(({node}) => node.group === filter)
    } else {
        curProducts = filterProducts;
    }

    let newTables = [];

    newTables = curProducts.map(({node}, index) => {
        return <ProductTable key={index} tablecontent={node.variations} title={node.name} />
    })

    function handleChange(e) {
        setFilter(e.target.value)

    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    function handleBlur(e) {
       e.preventDefault();
    }

    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <Header headerText="Product List" />
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <div className="control">
                        <div className={["select", "is-primary"].join(" ")}>
                        <select value={filter} onChange={handleChange} onBlur={handleBlur}>
                            <option value="">Filter by</option>
                            <option value="rsid">RSID</option>
                            <option value="hyliter">HYLITER</option>
                            <option value="onetouch">ONETOUCH</option>
                            <option value="amplicon">AMPLICON RX</option>
                            <option value="iplex">IPLEX</option>
                        </select>
                        </div>
                    </div>
                </div>
            </form>
            {newTables}
        </Layout>
    )
}

export const query = graphql `
    query {
        products: allProductsJson {
            edges {
                node {
                    id,
                    url,
                    name,
                    start_price,
                    image_thumb,
                    button,
                    excerpt,
                    group,
                    seo {
                        title,
                    },
                    variations {
                        sku,
                        product_name,
                        excerpt,
                        us_price
                    }
                }
            }
        }
    }
`